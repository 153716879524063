body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.Toastify__toast {
    border-radius: 0;
}

.Toastify__toast-icon {
    -webkit-margin-start: 5px;
    margin-inline-start: 5px;
    -webkit-margin-end: 15px;
    margin-inline-end: 15px;
}

.Toastify__toast-icon--info svg {
    fill: red;
}

.Toastify__toast--success {
    background-color: #1ab394;
    color: #fff;
}

.Toastify__toast--error {
    background-color: #ed5565;
    color: #fff;
}

.Toastify__toast--info {
    color: #277cfc;
}

.Toastify__toast--warning {
    background-color: orange;
    color: #fff;
}

.Toastify__close-button>svg {
    fill: #fff;
}

.Toastify__progress-bar--success {
    background: green;
}

 :root {
    --toastify-color-light: #fff;
    --toastify-color-dark: #fff;
    --toastify-color-info: #277cfc;
    --toastify-color-success: #fff;
    --toastify-color-warning: #fff;
    --toastify-color-error: #fff;
}

.react-confirm-alert-overlay {
    z-index: 999;
    background: rgba(0, 0, 0, 0.5);
}

.react-confirm-alert-body {
    line-height: 1.2;
    border-radius: 0;
    font-family: "Roboto", sans-serif;
    width: 450px;
    color: #666;
    white-space: break-spaces;
}

.react-confirm-alert-button-group {
    justify-content: flex-end;
    margin-top: 20px;
    padding-top: 20px;
}

.react-confirm-alert-button-group button {
    border-radius: 0;
    padding: 10px 25px;
    font-size: 15px;
    margin-right: 0;
    margin-left: 10px;
}

.react-confirm-alert-button-group button:first-child {
    background-color: #003456;
    font-weight: bold;
}

.react-confirm-alert-button-group button:last-child {
    background-color: transparent;
    border: 1px solid #d1d1d1;
    color: #003456;
}
